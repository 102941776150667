import React, { useRef } from "react"
import { useTrail, useChain, useSpring, animated } from "react-spring"
import { breakpoint } from "../../App"

import "./Title.scss"

const items = ["Aaron", "Wang"]
// var subs = ["Aspiring Software Engineer/Product Manager",
//  "Computer Science & Business Student @ USC", "Lead Developer @ Mischief.App", "VPA @ AKPSI"]
//  var counter = 0;
//Print three subs

const config = { mass: 5, tension: 4000, friction: 200 }

const Title = () => {
  const isLarge = window.innerWidth > breakpoint

  const springRef = useRef()
  const trailRef = useRef()

  const { opacity } = useSpring({
    ref: springRef,
    opacity: 1,
    from: { opacity: 0 },
  })

  const trail = useTrail(items.length, {
    ref: trailRef,
    x: 0,
    from: { x: 40 },
    config,
  })

  useChain([trailRef, springRef], [0.25, isLarge ? 1 : 0.4])

  return (
    <header className="title">
      <div className="name">
        {trail.map(({ x }, index) => (
          <div key={items[index]} className="name-split">
            <animated.div
              style={{
                transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
              }}
            >
              {items[index]}
            </animated.div>
          </div>
        ))}
      </div>

      {/* Headshot */}
      {/* <animated.img
        className="headshot"
        src={ aaron }
        alt="Portrait"
      /> */}

      <animated.h1
        className="intro"
        style={{
          opacity,
        }}
      >
        ➢ Software Engineer @{" "}
        <em>
          <a
            href="https://www.thumbtack.com/"
            target="blank"
            rel="noopener noreferrer"
            style={{ color: "rgb(70, 157, 211)" }}
          >
            Thumbtack{" "}
          </a>
          {/* 70, 157, 211 */}
        </em>
        {<br />}➢ Prev. SWE Intern @{" "}
        <em>
          <a
            href="https://www.linkedin.com/company/walmartglobaltech/"
            target="blank"
            rel="noopener noreferrer"
          >
            Walmart Labs
          </a>
        </em>
        {<br />}➢ Computer Science & Business @{" "}
        <strong>
          <a href="https://usc.edu" target="blank" rel="noopener noreferrer">
            USC
          </a>
        </strong>
      </animated.h1>
    </header>
  )
}

export default Title
