import React, { useRef } from "react"
import { useTrail, useChain } from "react-spring"

import SocialLink from "./SocialLink"
import { configBouncey } from "../../App"

import "./SocialLinks.scss"

const links = [
  {
    href: "https://github.com/aaronw4ng/",
    screenReaderText: "Github",
    svg: (
      <svg className="social-icon" viewBox="0 0 512 512">
        <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"></path>
      </svg>
    ),
  },
  {
    href: "https://www.linkedin.com/in/aaronw4ng/",
    screenReaderText: "LinkedIn",
    svg: (
      <svg className="social-icon" viewBox="0 0 512 512">
        <path d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z" />
      </svg>
    ),
  },
  {
    href: "mailto:aaronwang21@gmail.com?subject=Hey Aaron!",
    screenReaderText: "E-mail",
    svg: (
      <svg className="social-icon" viewBox="0 0 512 512">
        <path d="M101.3 141.6v228.9h0.3 308.4 0.8V141.6H101.3zM375.7 167.8l-119.7 91.5 -119.6-91.5H375.7zM127.6 194.1l64.1 49.1 -64.1 64.1V194.1zM127.8 344.2l84.9-84.9 43.2 33.1 43-32.9 84.7 84.7L127.8 344.2 127.8 344.2zM384.4 307.8l-64.4-64.4 64.4-49.3V307.8z" />
      </svg>
    ),
  },
  // {
  //   href: "https://aaron.imprint.to/",
  //   screenReaderText: "Imprint",
  //   svg: (
  //     <svg
  //       height="496pt"
  //       viewBox="-140 -70 700 700"
  //       width="496pt"
  //     >
  //       <path d="m398.855469 145.113281c.761719-5.664062 1.144531-11.394531 1.144531-17.113281 0-70.574219-57.425781-128-128-128-51.273438 0-97.257812 30.632812-117.382812 77.167969-15.449219-8.640625-32.761719-13.167969-50.617188-13.167969-57.34375 0-104 46.65625-104 104 0 45.089844 28.472656 84.175781 70.40625 98.375-4.214844 9.28125-6.40625 19.359375-6.40625 29.625 0 39.703125 32.304688 72 72 72 9.910156 0 19.734375-2.089844 28.832031-6.078125 18.144531 33.109375 53.0625 54.078125 91.167969 54.078125 38.0625 0 73.078125-21.039062 91.207031-54.191406 11.800781 4.113281 24.144531 6.191406 36.792969 6.191406 61.761719 0 112-50.238281 112-112 0-56.496094-42-103.59375-97.144531-110.886719zm-14.855469 206.886719c-13.015625 0-25.648438-2.566406-37.550781-7.632812-3.976563-1.703126-8.609375.097656-10.417969 4.039062-14.277344 31.347656-45.695312 51.59375-80.03125 51.59375-34.617188 0-66.136719-20.464844-80.304688-52.136719-.925781-2.078125-2.695312-3.664062-4.863281-4.351562-.792969-.253907-1.617187-.382813-2.433593-.382813-1.40625 0-2.8125.375-4.054688 1.101563-8.640625 5.082031-18.441406 7.769531-28.34375 7.769531-30.878906 0-56-25.128906-56-56 0-10.71875 3.089844-21.175781 8.929688-30.246094 1.40625-2.1875 1.664062-4.921875.6875-7.339844-.976563-2.40625-3.066407-4.191406-5.601563-4.78125-40.046875-9.320312-68.015625-44.527343-68.015625-85.632812 0-48.519531 39.480469-88 88-88 17.953125 0 35.257812 5.414062 50.023438 15.648438 2.046874 1.421874 4.65625 1.808593 7.046874 1.023437 2.378907-.785156 4.257813-2.632813 5.074219-5.007813 15.535157-45.257812 58.078125-75.664062 105.855469-75.664062 61.761719 0 112 50.238281 112 112 0 7.617188-.777344 15.238281-2.296875 22.671875-.472656 2.28125.082031 4.65625 1.503906 6.496094 1.425781 1.839843 3.585938 2.976562 5.914063 3.105469 50.957031 2.679687 90.878906 44.734374 90.878906 95.726562 0 52.9375-43.0625 96-96 96zm0 0" />
  //       <path d="m24 448c-13.230469 0-24 10.769531-24 24s10.769531 24 24 24 24-10.769531 24-24-10.769531-24-24-24zm0 32c-4.40625 0-8-3.585938-8-8s3.59375-8 8-8 8 3.585938 8 8-3.59375 8-8 8zm0 0" />
  //       <path d="m88 376c-22.054688 0-40 17.945312-40 40s17.945312 40 40 40 40-17.945312 40-40-17.945312-40-40-40zm0 64c-13.230469 0-24-10.769531-24-24s10.769531-24 24-24 24 10.769531 24 24-10.769531 24-24 24zm0 0" />
  //       <path d="m272 32v16c44.113281 0 80 35.886719 80 80h16c0-52.9375-43.0625-96-96-96zm0 0" />
  //     </svg>
  //   ),
  // },
  {
    href: "https://open.spotify.com/user/aaronw4ng",
    screenReaderText: "Spotify",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="800"
        width="1200"
        viewBox="-33.4974 -55.829 290.3108 334.974"
      >
        <path
          d="M177.707 98.987c-35.992-21.375-95.36-23.34-129.719-12.912-5.519 1.674-11.353-1.44-13.024-6.958-1.672-5.521 1.439-11.352 6.96-13.029 39.443-11.972 105.008-9.66 146.443 14.936 4.964 2.947 6.59 9.356 3.649 14.31-2.944 4.963-9.359 6.6-14.31 3.653m-1.178 31.658c-2.525 4.098-7.883 5.383-11.975 2.867-30.005-18.444-75.762-23.788-111.262-13.012-4.603 1.39-9.466-1.204-10.864-5.8a8.717 8.717 0 015.805-10.856c40.553-12.307 90.968-6.347 125.432 14.833 4.092 2.52 5.38 7.88 2.864 11.968m-13.663 30.404a6.954 6.954 0 01-9.569 2.316c-26.22-16.025-59.223-19.644-98.09-10.766a6.955 6.955 0 01-8.331-5.232 6.95 6.95 0 015.233-8.334c42.533-9.722 79.017-5.538 108.448 12.446a6.96 6.96 0 012.31 9.57M111.656 0C49.992 0 0 49.99 0 111.656c0 61.672 49.992 111.66 111.657 111.66 61.668 0 111.659-49.988 111.659-111.66C223.316 49.991 173.326 0 111.657 0"
          fill="#fff"
        />
      </svg>
    ),
  },
  {
    href: "https://aaronw4ng.github.io",
    screenReaderText: "Resume",
    svg: (
      <svg
        enableBackground="new 0 0 300 300"
        height="512"
        viewBox="0 0 300 300"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="m252.828 220h-12v-149.354c0-14.693-11.953-26.646-26.645-26.646h-130.464c-12.621 0-22.891 10.27-22.891 22.891v13.109c0 2.209 1.789 4 4 4h32v141.217c0 17.838 11.777 30.783 28 30.783h108.887c12.746 0 23.113-10.367 23.113-23.111v-8.889c0-2.209-1.789-4-4-4zm-184-144v-9.109c0-8.086 6.488-14.654 14.791-14.854 8.026.379 13.209 6.611 13.209 15.963v8zm72 154c0 9.562-7.473 17.457-17.008 17.973-.016 0-.027.002-.039.002-11.215-.539-18.953-9.746-18.953-22.758v-157.217c0-6.37-1.942-11.852-5.207-16h114.562c10.281 0 18.645 8.365 18.645 18.646v149.354h-88c-2.211 0-4 1.791-4 4zm108 2.889c0 8.332-6.781 15.111-15.113 15.111h-92.121c4.488-4.682 7.234-11.035 7.234-18v-2h100z" />
          <path d="m148.828 180c-2.211 0-4 1.791-4 4s1.789 4 4 4h48c2.211 0 4-1.791 4-4s-1.789-4-4-4z" />
          <path d="m212.828 156c0-2.209-1.789-4-4-4h-72c-2.211 0-4 1.791-4 4s1.789 4 4 4h72c2.211 0 4-1.791 4-4z" />
          <path d="m148.828 120c-2.211 0-4 1.791-4 4s1.789 4 4 4h48c2.211 0 4-1.791 4-4s-1.789-4-4-4z" />
          <path d="m212.828 100c0-2.209-1.789-4-4-4h-72c-2.211 0-4 1.791-4 4s1.789 4 4 4h72c2.211 0 4-1.791 4-4z" />
        </g>
      </svg>
    ),
  },
]

const SocialLinks = ({ inert }) => {
  const springRef = useRef()

  const trail = useTrail(links.length, {
    ref: springRef,
    config: configBouncey,
    y: 0,
    opacity: 1,
    // delay: 200,
    from: { y: 100, opacity: 0 },
  })

  useChain([springRef], [0.5])

  return (
    <ul className="social-links" inert={inert ? "" : undefined}>
      {trail.map((style, index) => {
        const item = links[index]
        return (
          <SocialLink
            style={style}
            key={item.href}
            href={item.href}
            screenReaderText={item.screenReaderText}
            svg={item.svg}
          />
        )
      })}
    </ul>
  )
}

export default SocialLinks
