import React, { useState, useEffect } from "react"
import useLocation from "wouter/use-location"
import { Helmet } from "react-helmet"
import { animated } from "react-spring"

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

import Dragger from "./Components/Dragger/Dragger"
import Title from "./Components/Title/Title"
import SocialLinks from "./Components/SocialLinks/SocialLinks"
import Detail from "./Components/Detail/Detail"
import Terminal from "./Components/Terminal/Term"

import "./index.scss"

import cardData from "./Data/data.js"

// Imgs
import aaron from "./imgs/aaronw.png"

export const breakpoint = 768
export const configMain = { tension: 500, friction: 80, mass: 3 }
export const configBouncey = { mass: 5, tension: 1600, friction: 100 }
export const prefersDarkMode = matchMedia(
  "(prefers-color-scheme: dark)"
).matches
export const colors = {
  white: "#fff",
  black: "#333",
}

const App = () => {
  const [isExpanded, setIsExpanded] = useState(null)

  // Routing
  const [location, setLocation] = useLocation()
  const matchedLocationFromData = cardData.find(item => item.path === location)
  useEffect(() => {
    if (matchedLocationFromData) {
      disableBodyScroll()
      setIsExpanded(matchedLocationFromData.title)
    } else {
      clearAllBodyScrollLocks()
      setIsExpanded(null)
    }
  }, [matchedLocationFromData])

  return (
    <div className="bg-color">
      <main className="container">
        <Helmet>
          <meta content="#efefef" name="theme-color" />
        </Helmet>
        <animated.img className="headshot" src={aaron} alt="Portrait" />
        <Title />
        <SocialLinks inert={!!isExpanded} />
        <Terminal />
        <div id="Work">
          <Dragger
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            setLocation={setLocation}
            inert={isExpanded}
          />
        </div>

        <Detail
          active={cardData.find(x => x.title === isExpanded)}
          handleClose={() => {
            setIsExpanded(null)
            clearAllBodyScrollLocks()
            window.history.back()
          }}
        />
      </main>
    </div>
  )
}

export default App
