import { useEffect, useState } from "react"
import axios from "axios"

function useFetch() {
  // Spotify Service
  const [songList, setSongList] = useState("")

  useEffect(() => {
    callBackendAPI().catch(err => console.log(err))
  }, [])

  // Fetching GET route from Express server
  const callBackendAPI = async () => {
    console.log("Calling Express server...")
    const url =
    "https://spotifyserver-production-7f07.up.railway.app/getCurrentlyPlayingSong"
    // Get currently playing song
    axios.get(url).then(function (response) {
      const data = response.data
      if (data != null) {
        var currentlyPlayingSong = [
          data.artistName,
          data.songTitle,
          data.imageURL,
          data.songURL,
          data.isCurrentlyPlaying,
        ]
      }
      setSongList(currentlyPlayingSong)
    })
  }
  return songList
}

export { useFetch }
