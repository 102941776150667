import groceryGuide from "../imgs/gg.png"
import slack from "../imgs/slack.png"
import bw from "../imgs/bw.png"
import tw from "../imgs/travelwell.svg"
import fire from "../imgs/fire.svg"
import cheerio from "../imgs/cheerio.svg"
import walmart from "../imgs/walmart.png"
import calendar from "../imgs/calendaricon.png"
import playbook from "../imgs/Playbook.svg"
import thumbtack from "../imgs/thumbtack.png"

// Technologies
import { ReactComponent as java } from "../icons/java.svg"
import { ReactComponent as firebase } from "../icons/firebase.svg"
import { ReactComponent as ReactIcon } from "../icons/react.svg"
import { ReactComponent as TensorFlow } from "../icons/tensor.svg"
import { ReactComponent as Google } from "../icons/google.svg"
import { ReactComponent as NodeB } from "../icons/npmblack.svg"
import { ReactComponent as NodeW } from "../icons/npmwhite.svg"
import { ReactComponent as SlackAPI } from "../icons/slack.svg"
import { ReactComponent as giphy } from "../icons/giphy.svg"
import { ReactComponent as giphyb } from "../icons/giphy-black.svg"
import { ReactComponent as Kubernetes } from "../icons/kubernetes.svg"
import { ReactComponent as Grafana } from "../icons/grafana.svg"
import { ReactComponent as Prometheus } from "../icons/prometheus.svg"
import { ReactComponent as Docker } from "../icons/docker.svg"
import { ReactComponent as SQLite } from "../icons/sqlite.svg"
import { ReactComponent as Figma } from "../icons/figma.svg"
import { ReactComponent as Three } from "../icons/three.svg"
import { ReactComponent as TS } from "../icons/ts.svg"
import { ReactComponent as PHP } from "../icons/php.svg"
import { ReactComponent as Go } from "../icons/go.svg"
import { ReactComponent as GraphQL } from "../icons/graphql.svg"

export default [
  {
    title: "Thumbtack",
    path: "/Thumbtack",
    offsetY: 50,
    icons: [
      {
        Icon: GraphQL,
        title: "GraphQLIcon",
      },
      {
        Icon: Go,
        title: "GoIcon",
      },
      {
        Icon: PHP,
        title: "PHPIcon",
      },
      {
        Icon: TS,
        title: "TypeScript",
      },
    ],
    url: "https://www.oculus.com/experiences/quest/4140357582721940/",
    imageLg: thumbtack,
    intro: `Experiments to optimize Thumbtack's search experience`,
    subtitle: `Experiments to optimize search experience for online services marketplace`,
    description: `<p>➢ Thumbtack is a late-stage Sequioa backed startup that delivers a two-sided marketplace to empower small businesses and homeowners alike.</p> <p>➢ I work at Thumbtack as a New Graduate Software Engineer on the Matching team, which works on a variety of problems from overall search experience to machine learning ranking models.</p> <p>➢ Within the Matching team, I can be found doing work in the Search Foundations lane, launching experiments to reduce mismatch within the context of search. Search mismatch is responsible for millions in lost revenue, thus improvements in this area can be highly lucrative.</p><p>➢ An example of a experiment I’ve recently worked on is revamping our ElasticSearch engine to include full-text search via fuzzy matching, lemmatization, and manual aliases.</p>`,
    theme: "rgb(0, 170, 217)",
    textColor: "#fff",
  },
  {
    title: "Playbook",
    path: "/Playbook",
    offsetY: 50,
    icons: [
      {
        Icon: ReactIcon,
        title: "React",
      },
      {
        Icon: TS,
        title: "TypeScript",
      },
      {
        Icon: Three,
        title: "Three",
      },
      {
        Icon: Figma,
        title: "Figma",
      },
    ],
    url: "https://www.oculus.com/experiences/quest/4140357582721940/",
    imageLg: playbook,
    intro: `Figma plugin that adds 3D to 2D UI/UX workflows`,
    subtitle: `Figma plugin that adds 3D to 2D UI/UX workflows`,
    description: `<p>➢ Playbook is a startup aiming to create the leading prototyping tool for cross-reality applications.</p> <p>➢ Users are able to replicate 2D workflows by creating component mockups in Figma, render the objects in 3D, and export for use in Unity.</p> <p>➢ In the VR application, users can utilize pre-built components such as buttons and panels to build rich 3D interfaces while in VR.</p><p>➢ With Playbook, I led the development of the Figma plugin that allows users to render components in 3D using Three.js and export them to Unity. </p>`,
    theme: "rgb(28, 28, 28)",
    textColor: "#fff",
  },
  {
    title: "Groupie",
    path: "/Groupie",
    offsetY: 50,
    icons: [
      {
        Icon: SQLite,
        title: "SQLite",
      },
      {
        Icon: Docker,
        title: "Docker",
      },
      {
        Icon: NodeW,
        title: "Node.js",
      },
      {
        Icon: java,
        title: "Java",
      },
    ],
    url: "https://github.com/aaronw4ng/Groupie/",
    imageLg: calendar,
    intro: `Full-stack app that helps users plan events with friends`,
    subtitle: `Full-stack app that helps users plan events with friends`,
    description: `<p>➢ Groupie is a website application that allows users to plan and schedule events with friends using Ticketmaster’s API. I developed Groupie alongside a team of 4 other software engineers from USC.</p> <p>➢ Our stakeholder provided us with a document detailing feature specifications. We worked across the course of 5 bi-monthly sprints to ensure Groupie adhered to each feature specification.</p><p>➢ We utilized test-driven development, building out JUnit and Cucumber tests prior to any development. We complied with GitFlow best practices, creating feature branches and requiring 100% coverage before merging with the main branch.</p><p>➢ Groupie features a custom Java servlet backend, interfacing with a SQLite database. On the frontend, we used HTML/CSS, JavaScript, and JSP. Docker-compose was utilized to ensure a consistent environment. </p>`,
    theme: "rgb(100, 49, 140)",
    textColor: "#fff",
  },
  {
    title: "Walmart Labs",
    path: "/WalmartLabs",
    offsetY: 50,
    icons: [
      {
        Icon: NodeW,
        title: "Node.js",
      },
      {
        Icon: Kubernetes,
        title: "Kubernetes",
      },
      {
        Icon: Grafana,
        title: "Grafana",
      },
      {
        Icon: Prometheus,
        title: "Prometheus",
      },
    ],
    url: "https://github.com/aaronw4ng/Cheerio-Frontend/",
    imageLg: walmart,
    intro: `Monitoring platform for Walmart's core service lines`,
    subtitle: `Platform that monitors performance of Walmart's core service lines`,
    description: `<p>➢ I worked as a Software Engineer intern for <a href = "https://walmartlabs.com/“ target = "_blank" rel = "noopener nofollow">Walmart Labs</a> for the summer for 2021 as the only intern on the Core Contact Modernization Team.</p> <p>➢ The team’s primary objective is to port Walmart’s service line system to Walmart’s Cloud Native Platform, enhancing scalability and performance. </p><p>➢ I was tasked with building a system that would monitor the service lines and provide visualized KPIs and metrics to evaluate performance and uptime.</p><p>➢ I built the system using Node.js, querying Walmart’s InContact API and visualizing metrics via Prometheus and Grafana. I deployed the application container to Walmart Cloud Native Platform, which maintains the application on a Kubernetes cluster.</p>`,
    theme: "rgb(17, 31, 61)",
    textColor: "#fff",
  },
  {
    title: "Cheerio",
    path: "/Cheerio",
    offsetY: 50,
    icons: [
      {
        Icon: firebase,
        title: "Firebase",
      },
      {
        Icon: NodeB,
        title: "Node.js",
      },
      {
        Icon: ReactIcon,
        title: "React.js",
      },
    ],
    url: "https://github.com/aaronw4ng/Cheerio-Frontend/",
    imageLg: cheerio,
    intro: `Mobile application that helps individuals with special needs understand their emotions`,
    subtitle: `Mobile application helping users understand and articulate their emotions`,
    description: `<p>➢ We conceived Cheerio through <a href = "https://usclavalab.org/" target = "_blank" rel = "noopener nofollow">LavaLab</a>, University of Southern California's premier startup incubator.</p><p>➢ I collaborated with a 4-person team consisting of 2 developer, a designer, and a product manager to ideate, design, develop, and pitch Cheerio.</p><p>➢ We conducted several user interviews and consulted with accredited psychiatrists and professionals to develop a user flow effective at helping individuals with autism understand and express their emotions.</p>➢ I developed Cheerio using React Native on the frontend and utilizing Firebase on the backend.<p>`,
    theme: "#fdfdcd",
  },
  {
    title: "FirePit",
    path: "/FirePit",
    offsetY: 50,
    icons: [
      // JS, React, Firebase, Java

      {
        Icon: firebase,
        title: "Firebase",
      },
      {
        Icon: NodeB,
        title: "Node.js",
      },
      {
        Icon: ReactIcon,
        title: "React.js",
      },
      {
        Icon: giphyb,
        title: "Giphy",
      },
      {
        Icon: java,
        title: "Java",
      },
    ],
    url: "https://tinyurl.com/FirePitApp",
    imageLg: fire,
    intro: `Website application Twitter clone allowing users to communicate via posted GIFs`,
    subtitle: `Twitter clone featuring GIF based communication`,
    description: `<p>➢ FirePit is a Twitter clone that utilizes GIF-based communication, ideated and developed by a team of 5 software engineers from USC.</p><p>➢ We worked together to create comprehensive documentation covering high-level requirements, technical specifications, detailed design, testing, and deployment.</p><p>➢I styled and implemented React componenets in accordance to the designs<p> <p>➢ My team and I implemented the backend using Firebase and Google Cloud Functions to incorporate multithreaded Java code allowing verified users to simultaneously post.</p>`,
    theme: "#f9c1c1",
  },
  {
    title: "Travel Well",
    path: "/TravelWell",
    offsetY: 50,
    icons: [
      {
        Icon: TensorFlow,
        title: "Tensorflow",
      },
      {
        Icon: NodeB,
        title: "Node.js",
      },
      {
        Icon: ReactIcon,
        title: "React.js",
      },
      {
        Icon: Google,
        title: "Google APIs",
      },
    ],
    url: "https://tinyurl.com/TravelWellApp",
    imageLg: tw,
    intro: `Native app disrupting public transportation created for KPMG Ideation Challenge 2020 Nationals`,
    subtitle: `Native app disrupting public transportation during COVID-19`,
    description:
      '<p>➢ Travel Well was created for the KPMG 2020 Ideation Challenge. I worked with a team of 4 to develop a disruptive solution that was 1/20 selected to compete nationally. <p>➢ I located a problem that COVID-19 presented (unsafe transportion) and developed a prototype, pitch deck, and business model consisting of market validation and target market analysis.</p> <p>➢ Our team conducted user interviews and incorporated feedback to develop product features. </p> <p>➢ We successfully pitched Travel Well to a panel of VCs and KPMG directors. Click the following <a href = "https://tinyurl.com/TravelWellApp" target = "_blank" rel = "noopener nofollow"> link</a> to learn more about Travel Well!</p>',
    theme: "RGB(155, 207, 255)",
  },
  {
    title: "Grocery Guide",
    path: "/GroceryGuide",
    offsetY: 50,
    icons: [
      {
        Icon: NodeB,
        title: "Node.js",
      },
      {
        Icon: ReactIcon,
        title: "React.js",
      },
      {
        //Google API, React Native, Node, Express, Expo, APIfy
        Icon: Google,
        title: "Google APIs",
      },
    ],
    url: "https://devpost.com/software/grocery-guide-ti9xf5",
    imageLg: groceryGuide,
    intro: `Mobile app that helps users safely grocery shop during the COVID-19 pandemic `,
    subtitle:
      "Mobile app helping users safely grocery shop during the COVID-19 pandemic ",
    description:
      '<p>➢ Grocery Guide was developed for a COVID-19 centric virtual <a href "https://uschackforhope.com" target = "_blank" rel="noopener nofollow"> Hackathon</a>. I worked with a team of USC students consisting of 2 product managers, 1 designer, and 3 engineers. <p>➢ I utilized <a href = "https://developers.google.com/maps/documentation/distance-matrix/start" target = "_blank" rel = "noopener nofollow">Google Distance Matrix</a>/<a href = "https://apify.com/drobnikj/crawler-google-places" target = "_blank" rel = "noopener nofollow">Places API</a> to retrieve nearby grocery stores and their occupancies by the hour.</p><p>➢ I scraped COVID-19 <a href = "https://corona-virus.la/data" target = "_blank" rel="noopener nofollow">safety data</a> for all Los Angeles neighborhoods into JSON format using <a href = "https://cheerio.js.org" target = "_blank" rel="noopener nofollow"> Cheerio.js.</a></p><p>➢ With React Native, I styled and implemented frontend components for the user registration and search flow.</p>',
    theme: "rgb(155, 230, 169)",
  },
  {
    title: "DailyNews",
    path: "/DailyNews",
    offsetY: 0,
    icons: [
      {
        //Node, Express, GIPhy, Slack API
        Icon: NodeW,
        title: "Node.js",
      },
      {
        Icon: giphy,
        title: "Giphy",
      },
      {
        Icon: SlackAPI,
        title: "SlackAPI",
      },
    ],
    url: "https://github.com/aaronw4ng/DailyNews",
    imageLg: slack,
    intro: `Slackbot that informs peers of daily news during COVID-19 pandemic`,
    subtitle: `Slackbot informing peers of daily news during the COVID-19 pandemic`,
    description:
      '<p>➢ I created a script that scrapes the top 5 news articles each day with <a href ="https://cheerio.js.org" target = "_blank" rel="noopener nofollow">Cheerio.js</a> and acquired a random GIF from the <a href = "https://developers.giphy.com/" target = "_blank" rel = "noopener nofollow">Giphy API</a>.</p> <p>➢ I used Node and Express to send daily news updates to a Slack web hook, enabling additional interactivity via POST.</p> <p>➢ I performed relevant unit testing and distributed Slackbot to engage an average of 82 users on a daily basis.</p>',
    theme: "rgb(68, 26, 73)",
    textColor: "#fff",
  },
  {
    title: "BetterWeather",
    path: "/BetterWeather",
    offsetY: 40,
    icons: [
      //React, openweather api, node, express\
      {
        Icon: NodeB,
        title: "Node.js",
      },
      {
        Icon: ReactIcon,
        title: "React",
      },
    ],
    url: "https://github.com/aaronw4ng/BetterWeather",
    imageLg: bw,
    intro: `Website application for users to observe weather forecasts worldwide`,
    subtitle: `Weather app for users to observe weather forecasts worldwide`,
    description:
      '<p>➢I engineered a Node app interacting with the <a href = "https://openweathermap.org/api" target = "_blank" rel = "noopener nofollow"> OpenWeatherMap API</a>, allowing users to filter city/temperature units.</p> <p>➢ To create a GUI, I designed and implemented a server-rendered client with Node, Express, EJS and hosted the app on Heroku.</p><p>➢ I then utilized React to build a responsive webpage that implements Javascript weather effects based on the returned condition.</p>',
    theme: "rgba(99.9%, 99.9%, 69.8%, 1)",
  },
]
