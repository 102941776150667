import React from "react";
// import { useState } from "react"
import { useFetch } from "../../Hooks/getCurrentlyPlayingSong";
// import Marquee from "react-fast-marquee"
// import ReactLoading from "react-loading"
// // import axios from "axios"
import "./Term.scss";

// // Function to check if no song is playing
// const isSongPlaying = data => {
//   console.log(data[4])
//   return data[4]
// }

// // Function to conditionally render the song title
// function renderSongTitle(data) {
//   // If API is sleeping, return a loading animation
//   if (data === "") {
//     return (
//       <div>
//         <div className="input-statement">Currently Listening To:</div>
//         <ReactLoading type="cylon" color="#ffffff" height="40px" width="53px" />
//       </div>
//     )
//   }
//   // Check if song is playing
//   if (isSongPlaying(data)) {
//     return (
//       <div>
//         <div className="input-statement">Currently Listening To:</div>
//         <Marquee
//           style={{
//             backgroundColor: "rgb(255, 253, 208, 0.2)",
//             marginBottom: "0.5rem",
//           }}
//           speed="40"
//           gradientColor="[255, 255, 255]"
//           direction="right"
//         >
//           <img
//             src={data[2]}
//             alt="Album Cover"
//             style={{ width: 40, height: 40, padding: 5, marginRight: 10 }}
//           />
//           <a href={data[3]} target="_blank" rel="noreferrer noopener">
//             {data[1]}
//           </a>
//           {"‎ by ‎"}
//           <div className="artistName">{data[0]}</div>
//         </Marquee>
//       </div>
//     )
//   }
//   if (!isSongPlaying(data)) {
//     return (
//       <div>
//         <div className="input-statement">Last Listened To:</div>
//         <Marquee
//           style={{
//             backgroundColor: "rgb(255, 253, 208, 0.2)",
//             marginBottom: "0.5rem",
//           }}
//           speed="40"
//           gradientColor="[255, 255, 255]"
//           direction="right"
//         >
//           <img
//             src={data[2]}
//             alt="Album Cover"
//             style={{ width: 40, height: 40, padding: 5, marginRight: 10 }}
//           />
//           <a href={data[3]} target="_blank" rel="noreferrer noopener">
//             {data[1]}
//           </a>
//           {"‎ by ‎"}
//           <div className="artistName">{data[0]}</div>
//         </Marquee>
//       </div>
//     )
//   }
// }

const Term = () => {
  // const [message, setMessage] = useState("")

  // const url = "spotifyserver-production-7f07.up.railway.app/sendMessage"
  // const url = "http://localhost:3000/sendMessage"

  // Function to handle change of texts
  // const handleChange = e => {
  //   setMessage(e.target.value)
  // }

  // Function to handle submission of message
  // const handleSubmit = e => {
  //   e.preventDefault()
  //   // setMessage("")
  //   // Use axios to post message to Express server
  //   axios.post(url, { Message: message }).then(res => {
  //     console.log(res)
  //   })
  // }

  const data = useFetch();
  console.log(data);
  return (
    <div className="about-container">
      <div className="terminal">
        <div className="terminal-header">
          <a href="http://www.aaronwang.xyz" rel="noopener noreferrer">
            <div className="header-button red" />
          </a>
          <div className="header-button yellow" />
          <div className="header-button green" />
          <span>Aaron Wang— -bash —</span>
        </div>
        <div className="terminal-window">
          <div className="statement">
            {/* --------------------------------- */}
            {/* {renderSongTitle(data)} */}
            {/* --------------------------------- */}
            <div className="input-statement"> Location:</div>
            <div className="return-statement">San Francisco, California</div>
            {/* --------------------------------- */}
            <div className="input-statement">Interests:</div>
            <div className="return-statement">
              Fashion,{" "}
              <a href="https://aaronw4ng.notion.site/Aaron-s-Favorite-Albums-2b808845333043a69dc4f8d5ef9865e3?pvs=74" target="_blank">Live Music and Collecting Vinyl</a>,
              Cooking, Running, Cold Showers
            </div>
            {/* --------------------------------- */}
            <div className="input-statement">Programming:</div>
            <div className="return-statement">
              Javascript (MERN), C++, PHP, Golang, HTML5/CSS3, Python, SQL,
              NoSQL
            </div>
            {/* --------------------------------- */}
            <div className="input-statement">Currently working on:</div>
            <div className="return-statement">
              1. Working on Matching and Search at{" "}
              <a
                href="https://www.thumbtack.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Thumbtack{" "}
              </a>
              <br /> 2. Learning how to sew
            </div>
            {/* --------------------------------- */}
            <div className="input-statement">Some of my work:</div>
            <div className="return-statement">
              Click <a href="#Work">here!</a>
            </div>
            {/* <div className="input-statement">Send me a message!</div>
            <div className="last-statement">
              <form onSubmit={handleSubmit}>
                <div className="messageDiv">
                  <label className="message-carat">{">"}</label>
                  <input
                    className="transparent"
                    type="text"
                    value={message}
                    onChange={handleChange}
                  />
                </div> */}
            {/* <span className="blinking">&nbsp;</span>
            </form> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Term;
